<template>
  <div class="maintainuser-form-page">
    <el-form
      ref="form"
      class="geology-form"
      :rules="formRules"
      :model="form"
      label-width="120px"
      style="margin-top: 30px"
    >
      <el-row>
        <el-col :span="10" :offset="1">
          <el-form-item label="运维单位" prop="MaintainUnitId">
            <el-select
              style="width: 100%"
              v-model="form.MaintainUnitId"
              filterable
              remote
              reserve-keyword
              default-first-option
              clearable
              placeholder="请选择运维单位"
              size="medium"
              :remote-method="getMaintainList"
              :loading="getMaintainLoading"
            >
              <el-option
                v-for="item in maintainSelect"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10" :offset="1">
          <el-form-item label="联系人" prop="Name">
            <el-input v-model="form.Name" size="small"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10" :offset="1">
          <el-form-item label="联系电话" prop="Mobile">
            <el-input v-model="form.Mobile" size="small"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10" :offset="1">
          <el-form-item label="头像" prop="HeadIcon">
            <single-file
              ref="singleFile"
              text="选择图片"
              :auto-upload="true"
              :show-file-list="true"
              :file-list="formHeadIcon"
              @success="uploadSuccess"
            ></single-file>
          </el-form-item>
        </el-col>
        <el-col :span="10" :offset="1">
          <el-form-item label="职位" prop="Position">
            <el-input v-model="form.Position" size="small"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10" :offset="1">
          <el-form-item label="登录密码" prop="Pwd">
            <el-input v-model="form.Pwd" size="small"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10" :offset="1">
          <el-form-item label="邮箱" prop="Email">
            <el-input v-model="form.Email" size="small"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10" :offset="1">
          <el-form-item label="排序" prop="SortCode">
            <el-input v-model="form.SortCode" size="small"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10" :offset="1">
          <el-form-item label="是否启用" prop="EnabledMark">
            <el-switch
              v-model="form.EnabledMark"
              :active-value="false"
              :inactive-value="true"
            >
            </el-switch>
          </el-form-item>
        </el-col>

        <el-col :span="23" :offset="1">
          <el-form-item>
            <el-button type="primary" size="medium" @click="onSubmit"
              >立即保存</el-button
            >
            <el-button
              type="primary"
              plain
              icon="el-icon-circle-close"
              size="medium"
              style="margin-left: 10px"
              @click="$router.go(-1)"
              >取消</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { SingleFile } from "@/components/upload";
import { getMaintainList, addMaintainUser, editMaintainUser } from "@/api/base";
export default {
  data() {
    return {
      form: {
        MaintainUnitId: "",
        Name: "",
        Mobile: "",
        Description: "",
        Position: "",
        HeadIcon: "",
        Email: "",
        EnabledMark: false,
        Pwd: "",
        UnitName: "",
        Id: 0,
        SortCode: 0,
      },
      formHeadIcon: [],
      formRules: {
        MaintainUnitId: [
          { required: true, trigger: "blur", message: "请选择运维单位" },
        ],
        Name: [{ required: true, trigger: "blur", message: "请输入姓名" }],
      },
      getMaintainLoading: false,
      maintainSelect: [],
    };
  },
  props: {
    formtype: {
      type: String,
      default: "add",
    },
    detail: {
      type: Object,
    },
  },
  components: {
    SingleFile,
  },
  methods: {
    getMaintainList(value) {
      this.getMaintainLoading = true;
      getMaintainList(1, 10, {
        fname: value,
      }).then((resdata) => {
        this.getMaintainLoading = false;
        if (resdata.code == 0) {
          this.maintainSelect = resdata.data.data;
        }
      });
    },
    uploadSuccess(file) {
      this.form.HeadIcon = file.path;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formtype != "edit") {
            addMaintainUser(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  setTimeout(() => {
                    this.$router.go(-1);
                  }, 500);
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          } else {
            editMaintainUser(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  setTimeout(() => {
                    this.$router.go(-1);
                  }, 500);
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.getMaintainList();
    if (this.formtype == "edit") {
      this.form = this.$store.state.global.MaintainUserInfo;
      this.formHeadIcon = [
        {
          url: this.form.HeadIcon,
        },
      ];
    }
  },
};
</script>